import axios from 'axios';

export const getNfd = async (address) => {
  try {
    const { data } = await axios.get(`https://api.nf.domains/nfd/lookup?address=${address}&limit=1&view=full`);
    return data[address];
  } catch (error) {
    return null;
  }
};
