import parseCID from './parseCID';

const getIpfsImageUrl = (hash, { width, quality } = { width: 1000, quality: 70 }) => {
  return `https://ipfs.algonode.xyz/ipfs/${hash}?optimizer=image&width=${width}&quality=${quality}`;
};

export default getIpfsImageUrl;

export const getImageUrl = async (nft) => {
  const url = nft.params.url;

  if (nft.params.url.includes('template-ipfs://{ipfscid:0:dag-pb:reserve:sha2-256}')) {
    const reserve = nft.params.reserve;
    const cid = parseCID(url, reserve);
    const ipfs = cid.toString();
    return getIpfsImageUrl(ipfs);
  }

  return getIpfsImageUrl(url.replace('ipfs://', ''));
};
