import clsx from 'clsx';
import React from 'react';
import PreviewBlocks from './PreviewBlocks';
import Button from '../Button';
import Container from '../Container';
import Image from 'next/image';

function Collections() {
  return (
    <div className='mt-12 md:mt-14 xl:mt-16 pb-12 bg-gray-800'>
      <div className={clsx('mx-auto md:px-4 lg:px-8 max-w-7xl')}>
        {/* <h2 className='text-2xl font-extrabold text-neutral-300 md:text-4xl'>Our Shitty Collections</h2> */}
        {/* <p className='mt-4 text-lg text-neutral-400'>View all the Shitty NFTS for sale on Algorand marketplaces.</p> */}

        <div className='flex md:grid md:grid-cols-1 md:gap-12 xl:gap-24 mt-8 overflow-scroll md:overflow-visible pb-3 md:pb-0'>
          <Collection
            preview={
              <div className='w-[550px] h-[550px] mr-16'>
                <Image
                  src='/accents/first-litter-kitties.png'
                  alt='Shitty Kitties First Litter'
                  width={600}
                  height={600}
                  className='object-contain relative'
                />
              </div>
            }
            image='/first-litter-collage.png'
            heading='First Litter'
            docsLink='https://docs.shittykitties.art/collections/first-litter'
            buyNow={`/marketplace?collection=First+Litter`}
            description={`Shitty Kitties First Litter is a collection of 500 shitty drawings of cartoon cats. Each kitty was originally purchased for 2 ALGO.`}
          />
          <Collection
            preview={
              <div className='w-[650px] h-[325px]'>
                <Image
                  src='/accents/second-litter-kitties.png'
                  alt='Shitty Kitties Second Litter'
                  width={2000}
                  height={1000}
                  className='object-contain relative'
                />
              </div>
            }
            image='/second-litter-collage.png'
            heading='Second Litter'
            docsLink='https://docs.shittykitties.art/collections/second-litter'
            buyNow={`/marketplace?collection=Second+Litter`}
            description={`Shitty Kitties Second Litter is a collection of 500 programmatically generated 1/1 profile picture style NFTs of the shittiest kitties you know and love.`}
            imagePosition='bottom'
          />
          <Collection
            preview={
              <div className='w-[650px] h-[325px]'>
                <Image
                  src='/accents/third-litter-kitties.png'
                  alt='Shitty Kitties Third Litter'
                  width={2000}
                  height={1000}
                  className='object-contain relative'
                />
              </div>
            }
            image='/third-litter-collage.png'
            heading='Third Litter'
            docsLink='https://docs.shittykitties.art/collections/third-litter'
            buyNow={`/marketplace?collection=Third+Litter`}
            description={`Third Litter is a collection of 1,069 shitty, hand-drawn, and programmatically generated NFTS on the Algorand blockchain.`}
            imagePosition='bottom'
          />
          <Collection
            preview={
              <div className='w-[550px] h-[550px] mr-16'>
                <Image
                  src='/accents/fourth-litter-kitties.png'
                  alt='Shitty Kitties First Litter'
                  width={750}
                  height={750}
                  className='object-contain relative'
                />
              </div>
            }
            image='/twitter-share-image.png'
            heading='Fourth Litter'
            docsLink='https://docs.shittykitties.art/collections/fourth-litter'
            buyNow={`/marketplace?collection=Fourth+Litter`}
            description={`Fourth Litter is a collection of 2,405 shitty, hand-drawn NFT kitties on the Algorand blockchain and is the final Litter in the Shitty Kitties family.`}
          />
          <Collection
            preview={
              <div className='w-[600px] h-[600px]'>
                <Image src='/accents/city.png' alt='Shitty Cities' width={1200} height={1200} className='object-contain relative' />
              </div>
            }
            image='/shitty-city.png'
            heading='Shitty Cities'
            docsLink='https://docs.shittykitties.art/collections/shitty-cities'
            buyNow={`/marketplace?collection=Shitty+Cities`}
            description={`Shitty Cities is the first and shittiest city building NFT Collection on the Algorand blockchain.`}
          />
          <Collection
            preview={
              <div className='w-[650px] h-[650px]'>
                <Image
                  src='/accents/city-blocks.png'
                  alt='Shitty Kitties First Litter'
                  width={600}
                  height={600}
                  className='object-contain relative'
                />
              </div>
            }
            image='/city-blocks.png'
            heading='Shitty City Blocks'
            buyNow={`/marketplace?collection=Shitty+City+Blocks`}
            docsLink='https://docs.shittykitties.art/collections/shitty-city-blocks'
            description={`Collect Shitty City Blocks to customize your Shitty Cities.`}
          />
          <Collection
            preview={
              <div className='w-[550px] h-[550px] mr-10'>
                <Image src='/accents/accessories.png' alt='Shitty Accessories' width={750} height={750} className='object-contain relative' />
              </div>
            }
            image='/accessories/cat-grass.png'
            heading='Shitty Accessories'
            buyNow={`/marketplace?collection=Shitty+Accessories`}
            docsLink='https://docs.shittykitties.art/collections/shitty-accesories'
            description='Shitty Kitties Accessories is a collection of NFTs used as rewards for holders of Shitty Kitty NFTs.'
          />
        </div>
      </div>
    </div>
  );
}

const Collection = ({ preview, heading, buyNow, docsLink, image, description, imagePosition = 'center' }) => {
  return (
    <div className='flex flex-col gap-5 w-full min-w-[340px] h-[420px] md:h-auto md:min-w-0 mx-4 md:mx-0'>
      <div className='w-full h-full relative rounded-lg group bg-gray-900 xl:h-[400px] flex flex-col xl:flex-row items-center'>
        <div className='xl:hidden absolute inset-0 z-20 bg-gray-900 bg-opacity-90 group-hover:bg-opacity-75 transition duration-300 rounded-lg'></div>
        <div className='xl:hidden'>
          <Image layout='fill' src={image} alt={heading} className='absolute inset-0 z-10 object-cover w-full h-full rounded-lg' />
        </div>

        <div className='p-8 xl:pl-16 xl:py-16 items-center flex flex-col flex-shrink-0 xl:w-2/3 w-full relative z-20'>
          <div className='w-full'>
            <h3 className='font-bold text-2xl md:text-4xl lg:text-5xl w-full whitespace-nowrap'>{heading}</h3>
            <div className='mt-4 max-w-[125px] md:max-w-[200px] lg:max-w-[250px]'>
              <Image src='/accents/underline.png' alt='accent underline' width={722} height={121} />
            </div>
          </div>

          <div className='mt-4 w-full'>
            <p className='text-gray-500 text-xl max-w-[500px]'>{description}</p>
          </div>

          <div className='mt-8 flex gap-4 w-full'>
            <Button href={buyNow}>Marketplace</Button>
            <Button theme='outline-gray' href={docsLink}>
              Learn More
            </Button>
          </div>
        </div>

        <div className={clsx('xl:flex hidden absolute right-0 items-center justify-center', imagePosition === 'bottom' && 'bottom-0')}>{preview}</div>
      </div>
    </div>
  );
};

export default Collections;
